<template>
    <v-flex class="order-1 order-lg-0">
        <v-layout class="shortcut-buttons justify-lg-end flex-wrap">
            <div class="d-contents">
                <v-btn
                    class="order-1 order-lg-0"
                    v-for="shortcut in visibleShortcuts"
                    :key="shortcut.id"
                    color="secondary"
                    outlined
                    @click="clickHandler(shortcut)"
                >
                    <Type :type="getType(shortcut)" />
                </v-btn>
            </div>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="order-0 order-lg-1"
                        color="secondary"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        @click="() => modalVisibility = true"
                    >
                        <v-icon>{{ ICONS.ADD_SHORTCUT }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('incidents.add_shortcuts') }}</span>
            </v-tooltip>
        </v-layout>
        <ManageShortcutsModal :dialog="modalVisibility" @close="closeModal" />
    </v-flex>
</template>

<script>
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import ManageShortcutsModal from '@/components/incidents/shortcuts/ManageShortcutsModal.vue';
import Type from '@/components/widgets/Type.vue';

const BUTTON_TEXT_LENGTH = 15;

export default {
    name: 'ShortcutsBar',
    components: { Type, ManageShortcutsModal },
    data: () => ({
        modalVisibility: false,
        ICONS
    }),
    props: {
        onClick: {
            type: Function,
            required: true
        }
    },
    computed: {
        ...mapState('incidents', [
            'visibleShortcuts'
        ])
    },
    methods: {
        closeModal () {
            this.modalVisibility = false;
        },
        clickHandler (shortcut) {
            const item = {
                incidentType: {
                    color: shortcut.color,
                    id: shortcut.id,
                    name: shortcut.name
                }
            };
            this.onClick(item);
        },
        getType (shortcut) {
            return {
                ...shortcut,
                name: shortcut.name.length > BUTTON_TEXT_LENGTH ? `${shortcut.name.substring(0, BUTTON_TEXT_LENGTH)}...` : shortcut.name
            };
        }
    }
};
</script>

<style scoped>
    .shortcut-buttons {
        gap: 12px;
    }
</style>

<template>
    <Modal :modal-title="`${$t('incident_shorcuts.manage_shortcuts_modal_title')} (${visibleShortcuts.length}/3)`"
           :dialog="dialog"
           max-width="1000px"
           fullWidth
           fullHeight
           :loading="isTypesLoading"
           scrollable
           @close="close">
        <template slot="content">
                <v-list class="py-0">
                    <v-list-item-group v-model="selected" multiple max="3">
                        <InfiniteScroll
                            v-for="(incidentType, index) in incidentTypes"
                            :key="incidentType.id"
                            :items-length="incidentTypes.length"
                            :index="index"
                            :item="incidentType"
                            @load-more-items="getIncidentsTypesLazyLoading"
                        >
                            <template>
                                <v-list-item :key="incidentType.id" :value="incidentType.id" @click="toggleVisibleShortcut(incidentType)">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active" readonly :disabled="!active && visibleShortcuts.length >= 3"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <Type :type="incidentType" />
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                                <v-divider
                                    v-if="index < incidentTypes.length - 1"
                                    :key="`${incidentType.id}-divider`"
                                />
                            </template>
                        </InfiniteScroll>
                    </v-list-item-group>
                </v-list>
        </template>
        <template slot="footer">
            <v-btn
                color="secondary"
                outlined
                @click="close"
            >
                {{ $t('buttons.ok') }}
            </v-btn>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/templates/Modal';
import { mapState } from 'vuex';
import Type from '@/components/widgets/Type.vue';
import InfiniteScroll from '@/components/widgets/InfiniteScroll.vue';
import debouncedIncidentTypes from '@/mixins/debounced/debouncedIncidentTypesV2';

export default {
    name: 'ManageShortcutsModal',
    components: { InfiniteScroll, Type, Modal },
    mixins: [
        debouncedIncidentTypes
    ],
    data: () => ({
        selected: []
    }),
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapState('incidents', [
            'incidentTypes',
            'visibleShortcuts'
        ])
    },
    methods: {
        close () {
            this.$emit('close');
        },
        toggleVisibleShortcut (shortcut) {
            const isVisible = this.visibleShortcuts.find(visibleShortcut => visibleShortcut.id === shortcut.id);

            if (isVisible) {
                return this.$store.commit('incidents/HIDE_SHORTCUT', shortcut.id);
            } else if (this.visibleShortcuts.length < 3) {
                return this.$store.commit('incidents/SHOW_SHORTCUT', shortcut);
            }
        }
    },
    watch: {
        visibleShortcuts (val) {
            if (val.length === 0) {
                this.selected = [];
            } else {
                this.selected = this.visibleShortcuts.map(shortcut => shortcut.id);
            }
        }
    },
    mounted () {
        this.selected = this.visibleShortcuts.map(shortcut => shortcut.id);
    }
};
</script>

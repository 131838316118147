<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <ValidationProvider :name="$attrs.label"
                        :vid="name"
                        :rules="rules"
                        v-slot="{ errors }"
                        tag="div">
        <v-combobox
            outlined
            :error-messages="errors"
            v-bind="$attrs"
            v-on="$listeners"
            @click:clear="clear"
        >
            <template slot="label">
                {{ $attrs.label }}
                <span v-if="isRequired" class="error--text">*</span>
            </template>
            <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" @click="clickItem(item)">{{item}}</v-list-item>
            </template>
            <template :slot="slotName">
                <slot :name="slotName"></slot>
            </template>
        </v-combobox>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import isFieldRequired from '@/mixins/isFieldRequired';
import { mapFields } from 'vuex-map-fields';

export default {
    name: 'Combobox',
    components: { ValidationProvider },
    mixins: [isFieldRequired],
    props: {
        slotName: {
            type: String,
            'default': ''
        },
        name: {
            type: String,
            'default': ''
        },
        checkDefault: {
            type: Boolean,
            'default': false
        },
        rules: {
            type: [String, Object, Array],
            'default': ''
        }
    },
    computed: {
        ...mapFields('manageIncident', [
            'incidentData.type'
        ])
    },
    methods: {
        clickItem (item) {
            this.$root.$emit('autocomplete', item);

            this.$store.dispatch('manageIncident/getIncidentPrefillAnswers', {
                questionId: this.name,
                incidentTypeId: this.type,
                answer: item
            });
        },
        clear () {
            this.$store.dispatch('manageIncident/clearIncidentQuestionsAnswers');
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";
    ::v-deep {
        & .v-input__icon--clear {
            & button {
                &::before {
                    font-size: 16px;
                }
            }
        }

        & .v-input--is-disabled {
            & .v-messages__message {
                font-size: $xxs;
            }
        }
    }
</style>

import { render, staticRenderFns } from "./Combobox.vue?vue&type=template&id=8e5c4650&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import script from "./Combobox.vue?vue&type=script&lang=js&"
export * from "./Combobox.vue?vue&type=script&lang=js&"
import style0 from "./Combobox.vue?vue&type=style&index=0&id=8e5c4650&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e5c4650",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VListItem } from 'vuetify/lib/components/VList';
installComponents(component, {VCombobox,VListItem})

<template>
    <span v-if="readonly">{{ questionValue }}</span>
    <Combobox
        v-else-if="!notApplicable"
        :label="label"
        :disabled="readonly"
        :name="id"
        :items="questionSuggestions"
        :prepend-icon="ICONS.AUTOCOMPLETE"
        clearable
        v-model="questionValue"
        :rules="required ? {required: true} : {}"
        @update:search-input="getQuestionSuggestions"
    />
</template>

<script>
import Combobox from '@/components/widgets/forms/Combobox.vue';
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import { mapFields } from 'vuex-map-fields';

export default {
    name: 'QuestionTextWithAutocomplete',
    components: { Combobox },
    data: () => ({
        questionValue: '',
        ICONS
    }),
    props: {
        id: {
            type: String
        },
        value: {
            type: String,
            'default': ''
        },
        label: {
            type: String,
            'default': ''
        },
        readonly: {
            type: Boolean,
            'default': true
        },
        required: {
            type: Boolean,
            'default': false
        },
        notApplicable: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        ...mapState('manageIncident', [
            'questionSuggestions'
        ]),
        ...mapFields('manageIncident', [
            'incidentData.type'
        ])
    },
    methods: {
        getQuestionSuggestions (search) {
            this.$store.dispatch('manageIncident/getIncidentQuestionSuggestions', {
                questionId: this.id,
                incidentTypeId: this.type,
                answer: search
            });
        }
    },
    created () {
        this.$root.$on('autocomplete', () => {
            this.questionValue = this.value;
        });

        this.questionValue = this.value;
    },
    watch: {
        value (val) {
            this.questionValue = val;
        }
    }
};
</script>

<style scoped lang="scss">
    ::v-deep {
        & .v-text-field {
            & .v-input__prepend-outer {
                margin-top: 6px;
                margin-bottom: 6px;
            }
        }
    }
</style>

<template>
    <div>
        <v-btn v-if="!isEditable"
               color="secondary"
               class="mt-5"
               data-test="toggleQuestions"
               :disabled="!hasQuestions"
               @click="$emit('toggleQuestions')">{{ (showQuestionForm && hasQuestions) ? $t('buttons.cancelQuestions') : $t('buttons.answerQuestions') }}</v-btn>
        <ValidationObserver ref="obs">
            <div
                v-if="showQuestionForm && hasQuestions"
                class="mt-5"
            >
                <h3 class="font-weight-medium mb-3">{{ $t('questions.title') }}</h3>
                <div class="wrapper">
                    <IncidentQuestion
                        v-for="(el) in incidentQuestions"
                        :key="el.id"
                        v-bind="el"
                        :item="el"
                        :answers="answers"
                    />
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import IncidentQuestion from '@/components/incidents/manageIncident/questionForm/IncidentQuestion';
import { ValidationObserver } from 'vee-validate';
import { mapFields } from 'vuex-map-fields';

export default {
    name: 'IncidentQuestionForm',
    components: { IncidentQuestion, ValidationObserver },
    data: () => ({
        answers: []
    }),
    props: {
        showQuestionForm: {
            type: Boolean,
            'default': false
        },
        isEditable: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        ...mapState('manageIncident', [
            'incidentQuestions'
        ]),
        ...mapFields('manageIncident', [
            'incidentData.type',
            'incidentData.locationId'
        ]),
        hasQuestions () {
            return this.type && this.locationId && this.incidentQuestions.length > 0;
        }
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            this.saveAnswers();
            return this.$refs.obs.validate();
        },
        saveAnswers () {
            const data = this.$refs.obs.refs;

            Object.keys(data).forEach(a => {
                const answerObject = this.answers.find(ans => ans.id === a);
                if (answerObject) {
                    answerObject.answer = data[a].value;
                    answerObject.answer_date_time = Date.now();
                    answerObject.is_invalid = false;
                    answerObject.incident = this.incidentType;
                    answerObject.question_template = answerObject.id;
                } else {
                    const relatedObject = this.answers.find(ans => ans.id === a.split('-')[0]);
                    this.answers.push({
                        ...relatedObject,
                        id: data[a].id,
                        answer: data[a].value,
                        answer_date_time: Date.now(),
                        is_invalid: false,
                        incident: this.incidentType,
                        question_template: relatedObject.id
                    });
                }
            });
        },
        getAnswers () {
            return this.answers;
        }
    },
    watch: {
        showQuestionForm (newValue) {
            if (!newValue) {
                this.answers = [];
            }
        },
        incidentQuestions: {
            handler (val) {
                const autocompleteQuestion = val.find(question => question.autocomplete);

                this.answers = [];

                if (!this.isEditable && autocompleteQuestion) {
                    this.$store.dispatch('manageIncident/getIncidentQuestionSuggestions', {
                        questionId: autocompleteQuestion.id,
                        incidentTypeId: this.type
                    });
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/_variables.scss";

.wrapper {
    display: flex;
    flex-wrap: wrap;
}
</style>
